import React, {useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/canopia.css';
import {Route, Routes, useNavigate} from "react-router-dom";

import Login from "./containers/auth/Login";
import Register from "./containers/admin/Register";
import Reload from "./containers/admin/Reload";
import ClientMonitoring from "./containers/canopia/ClientMonitoring";
import PrivateRoute from "./containers/auth/PrivateRoute";
import PublicRoute from "./containers/auth/PublicRoute";
import {GenericNotFound} from "./containers/core/GenericNotFound";
import Docs from "./containers/canopia/Docs";
import Terms from "./containers/auth/Terms";
import UserClientSettings from "./containers/setting/UserClientSettings";
import UserPwdChange from "./containers/auth/UserPwdChange";
import UserRequestPwdReset from "./containers/auth/UserRequestPwdReset";
import UserPwdReset from "./containers/auth/UserPwdReset";
import ReviewStatus from "./containers/review/ReviewStatus";
import OAuth2Login from "./containers/auth/OAuth2Login";
import Run from "./containers/admin/Run";
import LoggedOut from "./containers/auth/LoggedOut";
import AuthService from "./services/auth.service";
import Timeout from "./containers/auth/Timeout";
import Home from "./containers/canopia/Home";
import Import from "./containers/admin/Import";
import Setup from "./containers/admin/Setup";
import JobQueue from "./containers/admin/JobQueue";

function App() {

    const navigate = useNavigate();

    function resetIfTimeout() {
        if (AuthService.isExpired()) {
            AuthService.logout();
            navigate('/timeout');
            AuthService.resetStore();
        }
    }

    useEffect(() => {
        // Check if the token has expired
        resetIfTimeout();
        // And check it again...
        const interval = setInterval(() => {
            resetIfTimeout();
        }, 3000);
        return () => clearInterval(interval);
    });

    // render() {
    return (
        <Routes>
            {/* Public routes */}
            <Route path="/" element={<PublicRoute component={Home}/>}></Route>
            <Route path="/login" element={<PublicRoute component={Login}/>}></Route>
            <Route path="/login/oauth2" element={<PublicRoute component={OAuth2Login}/>}></Route>
            <Route path="/logged-out" element={<PublicRoute component={LoggedOut}/>}></Route>
            <Route path="/timeout" element={<PublicRoute component={Timeout}/>}></Route>
            <Route path="/request-reset-pwd" element={<PublicRoute component={UserRequestPwdReset}/>}></Route>
            <Route path="/reset-pwd" element={<PublicRoute component={UserPwdReset}/>}></Route>

            {/* Private routes */}
            <Route path="/change-pwd" element={<PrivateRoute component={UserPwdChange}/>}></Route>
            <Route path="/portfolio" element={<PrivateRoute component={ClientMonitoring}/>}></Route>
            <Route path="/docs" element={<PrivateRoute component={Docs}/>}></Route>
            <Route path="/policies" element={<PrivateRoute component={Terms}/>}></Route>
            <Route path="/user-client-settings" element={<PrivateRoute component={UserClientSettings}/>}></Route>
            <Route path="/review-status" element={<PrivateRoute component={ReviewStatus}/>}></Route>
            <Route path="/register" element={<PrivateRoute component={Register}/>}></Route>
            <Route path="/reload" element={<PrivateRoute component={Reload}/>}></Route>
            <Route path="/run" element={<PrivateRoute component={JobQueue}/>}></Route>
            <Route path="/import" element={<PrivateRoute component={Import}/>}></Route>
            <Route path="/setup" element={<PrivateRoute component={Setup}/>}></Route>
            <Route path="*" element={<PublicRoute component={GenericNotFound}/>}></Route>
        </Routes>
    );
}

export default App;
