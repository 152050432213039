import React, {useState} from "react";
import {ProgressBar} from "react-bootstrap";
import {CONTEXT_URL} from "../canopia/CanopiaUtils";
import {authHeader} from "../../services/auth-header";
import {StompSessionProvider, useStompClient, useSubscription} from "react-stomp-hooks";
import Run from "./Run";

const emptyQueueMsg = 'The job queue is empty';
const padding = '.5rem';

export default function JobQueue(props) {

    return (
        <div className="main-content">
            <div className={'setup-form-row'}>
                <Run/>
                <StompSessionProvider
                    //All options supported by @stomp/stompjs can be used here
                    connectHeaders={authHeader()}
                    reconnectDelay={5000}
                    url={CONTEXT_URL + 'api/ws'}
                    debug={(str) => {
                        console.log(str);
                    }}
                >
                    <div>
                        <div style={{
                            marginRight: "2rem"
                        }}>
                            <div key={-2} className={'canopia2 background-light'} style={{
                                display: "flex",
                                flexWrap: "nowrap",
                                flexGrow: "1",
                                padding: padding,
                                borderTop: "1px solid #bbc6d3"
                            }}>
                                <div style={{width: "50px", padding: padding}}>

                                </div>
                                <div style={{width: "50px", padding: padding}}>
                                    Job Id
                                </div>
                                <div style={{width: "120px", padding: padding}}>
                                    Start time
                                </div>
                                <div style={{width: "90px", padding: padding}}>
                                    User
                                </div>
                                <div style={{width: "70px", padding: padding}}>
                                    Client Id
                                </div>
                                <div style={{width: "60px", padding: padding}}>
                                    Pf date
                                </div>
                                <div style={{width: "70px", padding: padding}}>
                                    Consensus
                                </div>
                            </div>
                            <Subscription/>
                        </div>
                    </div>
                    <SendAfterSubscription/>
                </StompSessionProvider>
            </div>
        </div>
    );
}

export function Subscription() {
    // Job status
    const [runningJobs, setRunningJobs] = useState([]);
    const [jobsMessage, setJobsMessage] = useState('Connecting to the job queue... try to refresh the page if not successful');

    useSubscription('/api/admin/jobs-status', (msg) => {
        const jobs = JSON.parse(msg.body);
        console.log("Message received", jobs);

        const newJobs = [...jobs];
        newJobs.sort((a, b) => a.jobId - b.jobId);

        setRunningJobs(newJobs);
        if (newJobs.length === 0) {
            setJobsMessage(emptyQueueMsg);
        }
    });

    return runningJobs.length > 0 ? runningJobs.map(job => {
        return <div key={job.jobId} style={{
            display: "flex",
            flexWrap: "nowrap",
            flexGrow: "1",
            margin: "0",
            padding: padding,
            borderTop: "1px solid #bbc6d3"
        }}>
            <div style={{width: "50px", padding: padding}}>{job.startTime &&
                <ProgressBar animated now={100}/>}</div>
            <div style={{width: "50px", padding: padding}}>{job.jobId}</div>
            <div style={{width: "120px", padding: padding}}>{job.startTime}</div>
            <div style={{width: "90px", padding: padding}}>{job.firstname + ' ' + job.lastName}</div>
            <div style={{width: "70px", padding: padding}}>{job.clientId}</div>
            <div style={{width: "60px", padding: padding}}>{job.pfDate}</div>
            <div style={{width: "70px", padding: padding}}>{job.consensusId}</div>
        </div>;
    }) : <div style={{width: "320px", padding: padding}}>{jobsMessage}</div>;
}

export function SendAfterSubscription() {

    const stompClient = useStompClient();

    if (stompClient) {
        stompClient.publish({destination: "/app/job-queue", body: 'subscribed'});
    } else {
        // Handle error
    }

    return '';
}
