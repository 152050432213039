import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {authHeader} from "../services/auth-header";
import {CONTEXT_URL} from "../containers/canopia/CanopiaUtils";

const API_URL = CONTEXT_URL + "api/main/";

const initialState = {
    clientConfigs: [],
    clientConfig: null, //
    status: "idle",
    error: null,
    dnlStatus: "idle",
    dnlError: null,
    arcStatus: "idle",
    arcError: null,
}

export const loadClients = createAsyncThunk(
    'client/load',
    async () =>
        fetch(API_URL + "clients", {headers: authHeader(), cache: 'no-store'})
            .then(response => {
                let contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json();
                } else {
                    console.log("The content type is not JSON, found " + contentType);
                    return response;
                }
            })
    // .then(json => json)
    // .catch(error =>
    //     error
    // )
);

export const downloadExcelReport = createAsyncThunk(
    'client/download',
    async (params) =>
        window.location.href = API_URL + "export?date=" + params.date
);

export const downloadReportArchive = createAsyncThunk(
    'client/archive',
    async (params) =>
        window.location.href = API_URL + "archive?date=" + params.date
);

export const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        setClientConfig: (state, action) => {
            state.clientConfig = action.payload.clientConfig;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadClients.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(loadClients.fulfilled, (state, action) => {
                let payload = action.payload;

                if (payload.status) {
                    state.status = 'error';
                    state.error = payload;
                } else if (payload.message === 'Failed to fetch') {
                    state.status = 'error';
                    state.error = {
                        error: 'Service unavailable', //
                        message: 'We apologize for the inconvenience, our team is working on solving the issue. ' + //
                            'Please try to come back in a few minutes. Thank you for your patience.'
                    };
                } else {
                    state.status = 'success';
                    state.clientConfigs = payload["data"];
                }
            })
            .addCase(loadClients.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.payload;
            })
            .addCase(downloadExcelReport.pending, (state, action) => {
                state.dnlStatus = 'loading';
            })
            .addCase(downloadExcelReport.fulfilled, (state, action) => {
                state.dnlStatus = 'success';
            })
            .addCase(downloadExcelReport.rejected, (state, action) => {
                state.dnlStatus = 'error';
                state.dnlError = action.payload;
            })
            .addCase(downloadReportArchive.pending, (state, action) => {
                state.arcStatus = 'loading';
            })
            .addCase(downloadReportArchive.fulfilled, (state, action) => {
                state.arcStatus = 'success';
            })
            .addCase(downloadReportArchive.rejected, (state, action) => {
                state.arcStatus = 'error';
                state.arcError = action.payload;
            })
    }
});

export const {
    setClientConfig
} = clientSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectClientState = state => state.client;

export default clientSlice.reducer;