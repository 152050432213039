import React, {useEffect, useState} from "react";
import FlatSelect from "../FlatSelect";
import GradeChart from "./GradeChart";
import {CHART_TYPES, PF_KEY_SEPARATOR, THEMES, TOTAL} from "../CanopiaUtils";
import Modal from "react-bootstrap/Modal";
import {HiChevronRight} from "react-icons/hi";
import {useSelector} from "react-redux";
import {selectPortfolioState} from "../../../reducer/portfolioSlice";
import ESGMetricsDetailChart from "./ESGMetricsDetailChart";
import ESGMetricIcon from "../ESGMetricIcon";
import CO2Charts from "./CO2Charts";
import {createChartData} from "./ChartUtils";
import {DropdownButton} from "react-bootstrap";
import DropdownItem from "react-bootstrap/DropdownItem";

export default function DetailChartContainer(props) {

    const pfRowKey = props.pfRowKey;

    const theme = props.theme;
    const subTheme = props.subTheme;
    const subThemeValue = props.subThemeValue;
    const themes = props.themes;
    const changeTheme = props.changeTheme;
    const changeSubTheme = props.changeSubTheme;
    const changeSubThemeValue = props.changeSubThemeValue;
    const clearSubThemeValue = props.clearSubThemeValue;
    const date = props.date;
    const changePfDate = props.changePfDate;
    const isReview = props.isReview;
    const isDev = props.isDev;

    const [pfRow, setPfRow] = useState(null);
    const [pfRows, setPfRows] = useState(null);
    const [bmRow, setBmRow] = useState(null);

    const [chartType, setChartType] = useState(CHART_TYPES[0]);

    // const [chartType, setChartType] = useState(CHART_TYPES[0]);

    const {pfRowStatus} = useSelector(selectPortfolioState);

    const changePfKey = (v) => {
        const tgtPfRows = pfRowStatus[v].detail.pfRows;
        setPfRows(tgtPfRows);
        // Get the 1st row <= date as the rows are sorted by date desc
        const tgtPfRow = tgtPfRows.find(tgtRow => tgtRow.dateFmt <= date);
        if (tgtPfRow) {
            setPfRow(tgtPfRow);
            setBmRow(tgtPfRow.benchmark);
        }
    }

    if (!pfRow) {
        changePfKey(pfRowKey);
    }

    function changeChartType(type) {
        setChartType(type);
    }

    if (!pfRow) {
        return <>
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
                No data available for this portfolio, please select another one
            </Modal.Body>
        </>
    }

    // function changeChartType(type) {
    //     setChartType(type);
    // }

    let esgTheme = '';
    let dateSelect = '';

    // Add the histo data
    // Set the max date of the x-axis
    let maxDate;
    let dataSplit = date.split('-');
    // let dataSplit = pfRow.dateFmt.split('-');
    let lastDateObj = new Date(parseInt(dataSplit[0]), parseInt(dataSplit[1]) - 1, parseInt(dataSplit[2]));
    const now = new Date();
    if (now.getTime() > lastDateObj.getTime()) {
        maxDate = new Date(now.getFullYear() + 2, 0, 31).getTime();
    }

    let chartData = createChartData(pfRows, now);
    const name = pfRow.category;
    const bmName = bmRow ? bmRow.category : null;

    switch (theme.key) {
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // ESG REVIEW
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        case THEMES[0].key:
            esgTheme = <GradeChart pfRow={pfRow}
                                   bmRow={bmRow}
                                   data={chartData}
                                   theme={theme}
                                   subTheme={subTheme}
                                   subThemeValue={subThemeValue}
                                   changeSubThemeValue={changeSubThemeValue}
                                   clearSubThemeValue={clearSubThemeValue}
                                   changePfKey={changePfKey}
                                   pfName={name}
                                   bmName={bmName}
                                   date={date}
                                   maxDate={maxDate}
                                   isReview={isReview}/>;
            break;

        case THEMES[1].key: // CONTROVERSIES
        case THEMES[2].key: // IMPACTS
        case THEMES[4].key: // INITIATIVE
            // let chart;
            // switch (subTheme.key) {
            //     case themes[1].subThemes[0].key:
            //         chart = <SectorChart pfRow={pfRow} bmRow={bmRow} data={chartData[subTheme.key]} theme={theme} subTheme={subTheme}
            //                              pfNameValueFunc={changePfKey}
            //                              pfName={name} bmName={bmName} date={date} maxDate={maxDate}/>
            //         break;
            //     case themes[1].subThemes[1].key:
            //         chart = <ControversyChart data={chartData[subTheme.key]} theme={theme} subTheme={subTheme}
            //                                   pfName={name} bmName={bmName} maxDate={maxDate}/>
            //         break;
            // }
            esgTheme = <ESGMetricsDetailChart pfRow={pfRow}
                                              bmRow={bmRow}
                                              data={chartData[subTheme.key]}
                                              theme={theme}
                                              subTheme={subTheme}
                                              subThemeValue={subThemeValue}
                                              changeSubThemeValue={changeSubThemeValue}
                                              clearSubThemeValue={clearSubThemeValue}
                                              changePfKey={changePfKey}
                                              chartType={chartType}
                                              changeChartType={changeChartType}
                                              pfName={name}
                                              bmName={bmName}
                                              date={date}
                                              maxDate={maxDate}
                                              isReview={isReview}
                                              isDev={isDev}/>;
            break;

        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // CO2
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        case themes[3].key:
            esgTheme = <CO2Charts pfRow={pfRow}
                                  bmRow={bmRow}
                                  data={chartData[subTheme.key]}
                                  theme={theme}
                                  subTheme={subTheme}
                // subThemeValue={subThemeValue}
                //                   changeSubThemeValue={changeSubThemeValue}
                                  clearSubThemeValue={clearSubThemeValue}
                                  changePfKey={changePfKey}
                                  chartType={chartType}
                                  changeChartType={changeChartType}
                                  pfName={name}
                                  bmName={bmName}
                                  date={date}
                                  maxDate={maxDate}
                                  isReview={isReview}
                                  isDev={isDev}/>;
            break;
        default:
    }

    let dates = [];
    pfRows.forEach(pfRow => {
        if (!pfRow.hidden) {
            dates.push(pfRow.dateFmt);
        }
    });
    dateSelect = dates.length > 1 ?
        <DropdownButton id="dropdown-basic-button" title={date} value={date} variant={'default-dropdown'}>
            {dates.map(hDate => (
                hDate !== date &&
                <DropdownItem key={hDate} value={hDate} onClick={() => changePfDate(hDate)}
                              bsPrefix={'canopia-dropdown-item'}>
                    <span style={{fontWeight: 'normal'}}>{hDate}</span>
                </DropdownItem>
            ))}
        </DropdownButton> : dates.length === 1 ? <span className={'nowrap'}>{dates[0]}</span> : <></>;

    // Create the pf selector
    const pfKey = pfRow.key;
    const pfNames = pfKey.split(PF_KEY_SEPARATOR);
    let pfKeys = [];
    let prevKey = null;
    for (let i = 0; i < pfNames.length; i++) {
        const newPfKey = (prevKey ? prevKey : '') + pfNames[i];
        pfKeys.push(newPfKey);
        prevKey = newPfKey + PF_KEY_SEPARATOR;
    }
    let pfKeySelector = [];
    for (let i = 0; i < pfNames.length; i++) {
        const pfName = pfNames[i];
        const pfKey = pfKeys[i];
        pfKeySelector.push(i < pfNames.length - 1 ?
            <React.Fragment key={pfKey}>
                <span onClick={() => changePfKey(pfKey)} className={'canopia-nav-link'}>
                    {pfName}
                </span>
                <HiChevronRight size={'1.2em'} style={{margin: '0 10px 0 10px'}}/>
            </React.Fragment>
            :
            <span key={pfKey} className={'canopia-nav-link-active'}>{pfName}</span>
        );
    }
    const pfNav = <>
        {pfNames.length === 1 ?
            <p className='canopia detail-title-font'>{pfNames[0]}</p>
            :
            <p className='detail-title-font'>
                {pfKeySelector}
            </p>
        }
    </>;

    return <Modal.Body>
        {/*<div style={{width: '98%', margin: "auto", borderBottom: "1px solid #bbc6d3"}}>*/}
        <div style={{width: '100%', margin: "auto"}}>
            <div className={'detail-title'}>
                {pfNav}
            </div>
            <div className={'detail-title'}>
                <FlatSelect enums={themes} curValue={theme} curClass={'canopia-nav-link-active'}
                            otherClass={'canopia-nav-link'}
                            clickFunction={changeTheme} clickParamFromEnum={true}/>
            </div>
            {/*<div className={'detail-title'} style={{display: "grid", gridTemplateColumns: "40% 50% 100px"}}>*/}
            <div className={'detail-title'} style={{display: "flex", alignItems: "stretch"}}>
                <div style={{flexGrow: 1}}>
                    {theme.key === 'review' ? <div/> :
                        <FlatSelect enums={theme.subThemes} curValue={subTheme} curClass={'canopia-nav-link-active'}
                                    otherClass={'canopia-nav-link'}
                                    clickFunction={changeSubTheme} clickParamFromEnum={true}/>
                    }
                </div>
                <div style={{flexGrow: 1}}>
                    {subThemeValue === TOTAL ?
                        // Should be <p className={'detail-title-font'}> instead than h5
                        // It is a hack to display CO2 properly (the sub 2),
                        // don't know why h5 considers the font size of sub 2 but not with <p>
                        // <h5 style={{marginBottom: '2px', lineHeight: '24.375px'}}>{subTheme.label}</h5>
                        <div/>
                        :
                        <p className={'detail-title-font'}>
                            <span onClick={clearSubThemeValue} className={'canopia-nav-link'}>
                                All {subTheme.label}
                            </span>
                            <HiChevronRight size={'1.2em'} style={{margin: '0 10px 0 10px'}}/>
                            <span className={'canopia-nav-link-active'}>
                                {subThemeValue}
                            </span>
                            <ESGMetricIcon value={subThemeValue} status={'bad'}/>
                        </p>
                    }
                </div>
                <div style={{flexGrow: 1, textAlign: "right", margin: "-4px 0"}}>
                    {dateSelect}
                </div>
            </div>
        </div>
        {isDev && theme.key !== THEMES[0].key &&
            <div className={'detail-title'} style={{display: "flex", alignItems: "stretch"}}>
                <FlatSelect enums={CHART_TYPES} curValue={chartType} curClass={'canopia-nav-link-active'}
                            otherClass={'canopia-nav-link'}
                            clickFunction={changeChartType} clickParamFromEnum={true}
                            divStyle={{marginBottom: '4px'}}/>
            </div>
        }
        <div className={'detail-window'} style={{width: '100%', margin: "auto"}}>
            {esgTheme}
        </div>
    </Modal.Body>;
}
