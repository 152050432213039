import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {authHeader} from "../services/auth-header";
import {CONTEXT_URL} from "../containers/canopia/CanopiaUtils";

const API_URL = CONTEXT_URL + "api/admin/";

const initialState = {
    benchmarks: [],
    bmStatus: "idle",
    bmError: null
}

export const loadBM = createAsyncThunk(
    'benchmark/load',
    async () =>
        fetch(API_URL + "benchmark", {
            headers: authHeader(),
            cache: 'no-store'
        })
            .then(response => {
                let contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json();
                } else {
                    console.log("The content type is not JSON, found " + contentType);
                }
            })
            .then(json => json)
            .catch(error => error)
);

export const benchmarkSlice = createSlice({
    name: 'benchmark',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loadBM.pending, (state, action) => {
                state.bmStatus = 'loading';
            })
            .addCase(loadBM.fulfilled, (state, action) => {
                let payload = action.payload;
                if (payload.status) {
                    state.bmStatus = 'error';
                    state.bmError = payload;
                } else if (payload.message === 'Failed to fetch') {
                    state.bmStatus = 'error';
                    state.bmError = {
                        error: 'Service unavailable', //
                        message: 'We apologize for the inconvenience, our team is working on solving the issue. ' + //
                            'Please try to come back in a few minutes. Thank you for your patience.'
                    };
                } else {
                    state.bmStatus = 'success';
                    state.benchmarks = payload["data"];
                }
            })
            .addCase(loadBM.rejected, (state, action) => {
                state.bmStatus = 'error';
                state.bmError = action.payload;
            })
    }
});

// export const {} = benchmarkSlice.actions;

export const selectBenchmarkState = state => state.benchmark;

export default benchmarkSlice.reducer;