import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {authHeader} from "../services/auth-header";
import {CONTEXT_URL, filter, getNextSort, getProp, parseNull, sort} from "../containers/canopia/CanopiaUtils";

const API_URL = CONTEXT_URL + "api/review/";

const reviewStatusInitialSortStatus = {
    "client.displayName": "asc",
    "client.id": "asc",
    "job.job1DateFmt": "asc",
    "job.consensusIdInt": "asc",
    "job.job1CreateTimestampFmt": "asc",
    "job.job2CreateTimestampFmt": "asc",
    "job.job3CreateTimestampFmt": "asc",
    "job.job3ConsensusId": "asc",
    "job.job1PublicationTimestampFmt": "asc",
    "job.job1ReportPublicationTimestampFmt": "asc"
}

const initialState = {
    status: "idle",
    error: null,
    tgtDataOrig: null,
    tgtData: [], // The displayed ones, filtered, sorted, ...
    sortStatus: reviewStatusInitialSortStatus,
    sortCol: "job.job1CreateTimestampFmt",
    filterValuesOrig: {}, // all the values of the filters corresponding to directLinesOrig (initialized once, immutable then)
    filterValues: {}, // all the values of the filters
    filterSelectedValues: {}, // the current selected values of the filters
}

const filterCols = { //
    'client.displayName': 0, // 0 => the filter is inactive, 1 => 1st active filter, ...
    'job.job1DateFmt': 0, //
    'job.consensusIdInt': 0 //
};

function resetFilters(state) {
    state.filterValues = state.filterValuesOrig;
    state.filterSelectedValues = state.filterValuesOrig;
    Object.keys(filterCols).forEach(filterCol => {
        filterCols[filterCol] = 0;
    });
}

function resetSortStatus(state) {
    state.sortStatus = reviewStatusInitialSortStatus;
}

export const loadAllJobs = createAsyncThunk(
    'review/load',
    async () =>
        fetch(API_URL + "load-jobs", {headers: authHeader(), cache: 'no-store'})
            .then(response => {
                let contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json();
                } else {
                    console.log("The content type is not JSON, found " + contentType);
                }
            })
            .then(json => json)
            .catch(error => error)
);

export const reviewSlice = createSlice({
    name: 'review',
    initialState,
    reducers: {
        sortReviewStatus: (state, action) => {
            // load the portfolio of the selected client
            let col = action.payload.col;

            sort(state, col, reviewStatusInitialSortStatus);
        },
        filterReviewStatus: (state, action) => {
            // Update the filters with the new selection
            const colName = action.payload.colName;
            const selectedValues = action.payload.selectedValues;

            filter(state, colName, filterCols, selectedValues, reviewStatusInitialSortStatus);
        },
        clearAllReviewStatusFilters: (state, action) => {
            state.tgtData = state.tgtDataOrig;

            resetFilters(state);

            let prevSort = state.sortStatus[state.sortCol];
            state.sortStatus[state.sortCol] = getNextSort(prevSort);
            sort(state, state.sortCol, reviewStatusInitialSortStatus);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadAllJobs.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(loadAllJobs.fulfilled, (state, action) => {
                let payload = action.payload;
                if (payload.status) {
                    state.status = 'error';
                    state.error = payload;
                } else if (payload.message === 'Failed to fetch') {
                    state.status = 'error';
                    state.error = {
                        error: 'Service unavailable', //
                        message: 'We apologize for the inconvenience, our team is working on solving the issue. ' + //
                            'Please try to come back in a few minutes. Thank you for your patience.'
                    };
                } else {
                    state.status = 'success';
                    state.tgtDataOrig = payload["data"];
                    state.tgtData = state.tgtDataOrig;

                    if (state.tgtDataOrig) {
                        const tmpValues = {};
                        Object.keys(filterCols).forEach(filterCol => {
                            tmpValues[filterCol] = [];
                        });
                        state.tgtDataOrig.forEach(dLine => {
                            Object.keys(filterCols).forEach(filterCol => {
                                let cols = filterCol.split('.');
                                let nbCols = cols.length;
                                const value = parseNull(getProp(dLine, cols, nbCols));
                                if (!tmpValues[filterCol].includes(value)) {
                                    tmpValues[filterCol].push(value);
                                }
                            });
                        });
                        // Sort the filter values
                        Object.keys(tmpValues).forEach(filterCol => {
                            tmpValues[filterCol].sort();
                        });
                        state.filterValuesOrig = tmpValues;

                        resetFilters(state);
                        resetSortStatus(state);

                        sort(state, state.sortCol, reviewStatusInitialSortStatus);
                    }
                }
            })
            .addCase(loadAllJobs.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.payload;
            })
    }
});

export const {
    sortReviewStatus,
    filterReviewStatus,
    clearAllReviewStatusFilters,
} = reviewSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectReviewState = state => state.review;

export default reviewSlice.reducer;