import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {Provider} from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from "./store/store";
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<React.StrictMode>
<Provider store={store}>
    <Router>
    <App/>
    </Router>
</Provider>
</React.StrictMode>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// import React from 'react'
// import ReactDOM from 'react-dom'
// import Form from './containers/login/Form'
//
// const inputs = [{
//     name: "username",
//     placeholder: "username",
//     type: "text"
// },{
//     name: "password",
//     placeholder: "password",
//     type: "password"
// },{
//     type: "submit",
//     value: "Submit",
//     className: "btn"
// }]
//
// const props = {
//     name: 'loginForm',
//     method: 'POST',
//     action: '/canopia/perform_login',
//     inputs: inputs
// }
//
// const params = new URLSearchParams(window.location.search)
//
// ReactDOM.render(
//     <Form {...props} error={params.get('error')} />,
//     document.getElementById('container')
// );